import React, { useState, useEffect, useRef } from 'react'
import {
  makeStyles,
  responsiveFontSizes,
  withStyles
} from '@material-ui/core/styles'
import { Tabs, Grid, Tab, Typography } from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import PayRateHistory from '../components/PayRateHistory'
import PersonalActHistory from '../components/PersonalActHisotry'
import Deductions from '../components/Deductions'
import EmployeeChecks from '../components/EmployeeChecks'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Main from '../components/Main'
import PayrollHistory from '../components/PayrollHistory'
import FieldHistory from '../components/FieldHistory'
import LoadingButton from '../components/LoadingButton'
import UserFields from '../components/UserFields'
import PersonalActions from '../components/PersonalActions'
import EmployeeTax from '../components/EmployeeTax'
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  TextField,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Benefits from '../components/Benefits'
import Dependents from '../components/Dependents'
import LoadingCircle from '../components/common/LoadingCircle'
import { AntTabs } from '../components/VendorTabs'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import fetchFromAthena from '../functions/FetchFromAthena'

const searchByOptions = [
  {
    label: 'SSN',
    id: 'FICA_NBR'
  },
  {
    label: 'Email',
    id: 'EMAIL_ADDRESS'
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(1)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: '#3F51B6'
  }
}))

export default function CustomizedTabs (props) {
  const { credentials } = props.fetchInitialData
  const classes = useStyles()
  const [state, setState] = useState({
    employee: null,
    history: null,
    actions: null,
    deductions: null,
    checks: null,
    fields: null,
    userFields: null,
    years: [],
    benefits: null,
    dependents: null,
    dependentBenefits: null,
    reviews: null,
    certificates: null,
    disciplinaryActions: null,
    taxHeader: null,
    taxDetail: null,
    companies: [],
    fetched: false
  })
  const [showTable, setShowTable] = useState(false)
  const [employeeKey, setEmployeeKey] = React.useState(false)
  const [loadingType, setLoadingType] = React.useState(null)
  const [isLoading, setLoading] = React.useState(false)
  const [empId, setEmpId] = React.useState(null)
  const [company, setCompany] = React.useState('')
  const [value, setValue] = React.useState(0)
  const [inputVal, setInputVal] = React.useState('')
  const [advSearchOpen, setAdvSearchOpen] = React.useState(false)
  const [searchByVal, setSearchByVal] = React.useState(null)
  const [isSearching, setSearching] = React.useState(false)

  const subPagePermission = props.fetchInitialData.credentials.user.roleActions.reduce(
    (acc, cur) => {
      if (cur.type === 'subpage' && cur.filter1 === "HR") {
        if (acc[cur.filter2]) {
          acc[cur.filter2] = acc[cur.filter2] ? true : cur.active;
        } else {
          acc[cur.filter2] = cur.active === 1 ? true : false;
        }
      }
      return acc;
    },
    {}
  );

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const resp = await LambdaFetch(
          'employee-form',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'company'
          }),
          '',
          credentials
        )
        // const permissionedCompanies = resp.data.company.filter(c => props.fetchInitialData.credentials.userPermissions('company',c))
        setState({ ...state, companies: resp.data.company, fetched: true })
      } catch (e) {
        console.log(e)
      }
    }
    getCompanies()
  }, [])

  const getNextEmployee = async (id, type) => {
    setLoadingType(type)
    setEmployeeKey(!employeeKey)
    const next = await getEmployee(id)
    setInputVal(`${next.first} ${next.last} (${next.id})`)
    setLoadingType(null)
  }
 
  const handleSubmit = async (employee) => {
    setLoading(true)
    await getEmployee(employee)
    setLoading(false)
  }
  const getEmployee = async (employee) => {
    const queries = []

    queries.push(`employee_main_read${subPagePermission['main'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`pay_rate_hist_read${subPagePermission['pay-rate-history'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`pers_act_read${subPagePermission['personnel-action'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`emp_deduction_read${subPagePermission['deductions'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`emp_payroll_year_read${subPagePermission['payroll-history'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`employee_checks_read${subPagePermission['pay-stubs'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`padict_fields_read${subPagePermission['field-history'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`user_field_read${subPagePermission['user-fields'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`benefits_read${subPagePermission['benefits'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`dependent_read${subPagePermission['dependents'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`dependent_benefit_read${subPagePermission['dependents'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`employee_reviews_read${subPagePermission['reviews'] || subPagePermission['-1'] ? '' : 'false'}`)
    queries.push(`employee_certifications_read${subPagePermission['certifications'] || subPagePermission['-1'] ? '' : 'false'}`)  
    queries.push(`employee_disciplinary_actions${subPagePermission['disciplinary'] || subPagePermission['-1'] ? '' : 'false'}`)  
    queries.push(`employee_tax_header_read${subPagePermission['tax'] || subPagePermission['-1'] ? '' : 'false'}`)  
    queries.push(`employee_tax_detail_read${subPagePermission['tax'] || subPagePermission['-1'] ? '' : 'false'}`)  

    const data = await Promise.allSettled(
      queries.map(query =>
        fetchFromAthena({
          queryName: query,
          headers: {
            company,
            empId: employee ? employee : empId
          },
          accessToken: credentials.user.accessToken,
          credentials: credentials
        })
      )
    ).then(results => {
      return results
    })

    console.log(data)
    setState({
      ...state,
      employee: data[0]?.value?.[0] || {},
      history: data[1]?.value || [],
      actions: data[2]?.value || [],
      deductions: data[3]?.value || [],
      years: data[4]?.value || [],
      checks: data[5]?.value || [],
      fields: data[6]?.value || [],
      userFields: data[7]?.value || [],
      benefits: data[8]?.value || [],
      dependents: data[9]?.value || [],
      dependentBenefits: data[10]?.value || [],
      reviews: data[11]?.value || [],
      certificates: data[12]?.value || [],
      disciplinaryActions: data[13]?.value || [],
      taxHeader: data[14]?.value?.[0] || {},
      taxDetail: data[15]?.value || [],
    })
    setShowTable(true)
  }
  const advSearch = async event => {
    event.preventDefault()
    const searchBy = searchByVal
    const searchVal = event.target.searchValue.value
    if (!(searchBy && searchVal)) {
      return null
    }
    setSearching(true)
    const resp = await LambdaFetch(
      'employee-search',
      'post',
      credentials.user.accessToken,
      JSON.stringify(
        {
          lookupType: searchBy,
          lookupVal: searchVal,
          company: company
        },
        '',
        credentials
      )
    )
    console.log(resp)
    const emp = resp.data.emp
    if (!emp || !emp.EMPLOYEE) {
      props.createSnack('No employee found', 'warning', 3000)
    } else {
      await handleSubmit(emp.EMPLOYEE)
      setAdvSearchOpen(false)
    }
    setSearching(false)
  }
  const advHelperText =
    searchByVal === 'FICA_NBR' ? 'Must be in form XXX-XX-XXXX' : ''

  const {
    employee,
    history,
    actions,
    deductions,
    years,
    checks,
    fields,
    userFields,
    benefits,
    dependents,
    dependentBenefits,
    reviews,
    certificates,
    disciplinaryActions,
    taxHeader,
    taxDetail,
  } = state

  const constructHrTabs = () => {
    const permissions = {}
    const hrTabs = []
      if(subPagePermission['main'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Main',
          value: 0
        })
        permissions['main'] = true
      } 
      if(subPagePermission['pay-rate-history'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Pay Rate History',
          value: 1
        })
        permissions['pay-rate-history'] = true
      } 
      if(subPagePermission['personnel-action'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Personnel Action',
          value: 2
        })
        permissions['personnel-action'] = true
      } 
      if(subPagePermission['tax'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Tax PR13',
          value: 3
        })
        permissions['tax'] = true
      } 
      if(subPagePermission['deductions'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Deductions (PR15.2)',
          value: 4
        })
        permissions['deductions'] = true
      } 
      if(subPagePermission['payroll-history'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Payroll History (PR52.1)',
          value: 5
        })
        permissions['payroll-history'] = true
      } 
      if(subPagePermission['pay-stubs'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Pay Stubs',
          value: 6
        })
        permissions['pay-stubs'] = true
      } 
      if(subPagePermission['field-history'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Field History',
          value: 7
        })
        permissions['field-history'] = true
      } 
      if(subPagePermission['user-fields'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'User Fields',
          value: 8
        })
        permissions['user-fields'] = true
      } 
      if(subPagePermission['benefits'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Benefits',
          value: 9
        })
        permissions['benefits'] = true
      } 
      if(subPagePermission['dependents'] || subPagePermission['-1']) {
        hrTabs.push({
          display: 'Dependents',
          value: 10
        })
        permissions['dependents'] = true
      } 
    return {hrTabs,permissions}
  }
  const {hrTabs,permissions} = constructHrTabs()

  return (
    <div className={classes.root}>
      <Paper
        elevation={0}
        style={{ marginTop: '2rem', width: '100%', padding: '1rem' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <div style={{ margin: '0 1rem 0 0' }}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-start'
                spacing={3}
              >
                <Grid item>
                  <AutocompleteDropDown
                    {...props}
                    id={'company'}
                    name={'company'}
                    width='160px'
                    key={'company'}
                    required={true}
                    variant='standard'
                    label={'Company'}
                    value={company}
                    category={'company'}
                    screen={'employee'}
                    onChange={val => setCompany(val ? val.key_value : '')}
                  />
                </Grid>
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={!company}
                    required={true}
                    key={employeeKey}
                    id='employee'
                    width='320px'
                    label='Employee'
                    accessToken={credentials.user.accessToken}
                    error='No Employee found'
                    variant='standard'
                    handleChange={(id, hit) => {
                      setEmpId(id)
                    }}
                    credentials={credentials}
                    apiResource='autocomplete-sql'
                    secondaryParams={{
                      limit: 100,
                      company: company
                    }}
                    dontOpenOnFocus
                    helper={
                      <>
                        <div style={{ position: 'absolute' }}>
                          {'Search for ID or name or '}
                          <span
                            style={{ fontWeight: 500 }}
                            className={!company ? null : 'editLink'}
                            color='primary'
                            size='small'
                            variant='contained'
                            onClick={() => {
                              return !company ? null : setAdvSearchOpen(true)
                            }}
                          >
                            Advanced Search
                          </span>
                        </div>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: advSearchOpen ? 'inline-block' : 'none',
                position: 'absolute',
                zIndex: 999
              }}
            >
              <Paper elevation={2} style={{ padding: '1rem' }}>
                <Typography variant='h6'>Advanced Search</Typography>
                <IconButton
                  style={{ position: 'absolute', right: 0, top: 0 }}
                  onClick={() => setAdvSearchOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
                <form onSubmit={advSearch}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        id='searchBy'
                        options={searchByOptions}
                        onChange={(event, value) => {
                          value === null
                            ? setSearchByVal(null)
                            : setSearchByVal(value.id)
                        }}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                          <TextField
                            {...params}
                            margin='dense'
                            label='Search By'
                            variant='outlined'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='searchValue'
                        label='Value'
                        fullWidth
                        variant='outlined'
                        margin='dense'
                        helperText={advHelperText}
                        autoComplete='off'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        label='Search'
                        isLoading={isSearching}
                        color='primaryVLButton'
                        buttonType='submit'
                      />
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </div>
          </Grid>
          <div style={{ marginTop: '1rem' }}>
            <LoadingButton
              disabled={!company || !empId}
              label='Submit'
              isLoading={isLoading}
              color='primaryVLButton'
              fn={() => handleSubmit(empId)}
            />
          </div>
        </Grid>
      </Paper>

      {showTable && (
        <Card style={{ margin: '1rem auto' }}>
          <CardContent style={{ position: 'relative' }}>
            <div>
              {/* {!!loadingType ? (
                <LoadingCircle />
              ) : ( */}
              <>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '0.5rem',
                    justifyContent: 'flex-start'
                  }}
                >
                  <div>
                    <Typography variant='h5'>{`${employee.FIRST_NAME} ${
                      employee.MIDDLE_INIT ? `${employee.MIDDLE_INIT}.` : ''
                    } ${employee.LAST_NAME}`}</Typography>
                    <Typography variant='subtitle1'>{`Employee ID: ${employee.EMPLOYEE}`}</Typography>
                  </div>
                </div>
                <div className={classes.demo1}>
                  <AntTabs
                    value={value}
                    color={credentials.primaryAppColor}
                    handleChange={(event, newValue) => {
                      setValue(newValue)
                    }}
                    controlledTabs
                    tabs={hrTabs}
                  />

                  <Typography className={classes.padding} />
                  {value === 0 && permissions['main'] && <Main data={employee} />}
                  {value === 1 && permissions['pay-rate-history'] &&  (
                    <PayRateHistory
                      fileName={`Pay_Rate_History_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      data={history}
                    />
                  )}
                  {value === 2 && permissions['personnel-action'] && (
                    <PersonalActions
                      data={{ actions, reviews, certificates, disciplinaryActions }}
                      empId={employee.EMPLOYEE}
                      employee={employee}
                      fileName={`Personnel_Action_History_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      company={company}
                      accessToken={credentials.user.accessToken}
                      {...props}
                    />
                  )}
                  {value === 3 && permissions['tax'] && (
                    <EmployeeTax
                      {...props}
                      data={{
                        taxHeader,
                        taxDetail
                      }}
                      fileName={`Tax_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                    />
                  )}
                  {value === 4 && permissions['deductions'] && (
                    <Deductions
                      data={deductions}
                      fileName={`Deductions_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                    />
                  )}
                  {value === 5 && permissions['payroll-history'] && (
                    <PayrollHistory
                      {...props}
                      employee={employee}
                      company={company}
                      fileName={`Payroll_History_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      years={years.sort((a,b) => a.PAYROLL_YEAR - b.PAYROLL_YEAR).map(yr => yr.PAYROLL_YEAR)}
                      accessToken={credentials.user.accessToken}
                    />
                  )}
                  {value === 6 && permissions['pay-stubs'] &&(
                    <EmployeeChecks
                      accessToken={credentials.user.accessToken}
                      empId={employee.EMPLOYEE}
                      fileName={`Pay_Stubs_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      company={company}
                      data={checks}
                      employee={employee}
                      {...props}
                    />
                  )}
                  {value === 7 && permissions['field-history'] && (
                    <FieldHistory
                      accessToken={credentials.user.accessToken}
                      fileName={`Field_History_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      empId={employee.EMPLOYEE}
                      company={company}
                      fields={fields}
                      {...props}
                    />
                  )}
                  {value === 8 && permissions['user-fields'] && (
                    <UserFields
                      {...props}
                      data={userFields}
                      fileName={`User_Fields_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                    />
                  )}
                  {value === 9 && permissions['benefits'] && (
                    <Benefits
                      {...props}
                      data={benefits}
                      fileName={`Benefits_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                    />
                  )}
                  {value === 10 && permissions['dependents'] && (
                    <Dependents
                      {...props}
                      data={dependents}
                      fileName={`Dependents_${employee.FIRST_NAME}_${employee.LAST_NAME}`}
                      dependentBenefitsData={dependentBenefits}
                    />
                  )}
                </div>
              </>
              {/* )} */}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
